import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable, of } from 'rxjs';

import { UserService } from '@modules/auth/services';
import { AccountsEmployee, CheckErrorPf, Default, GetResponseAccountsEmployee, GetResponseCheckErrorPf, GetResponseModalDetalleError, GetResponseResultDln, GetResponseValidacionesModalDln, GetResponseValidarParametroDln, ModalDetalleError, ResultDln, ValidacionesModalDln, ValidarParametroDln } from '../models';

@Injectable({ providedIn: 'root' })
export class OperationsTkService {

    private http = inject(HttpClient);
    private userService = inject(UserService);

    getOperationsTk$(): Observable<{}> {
        return of({});
    }

    MEDICAL_TK_REPORTS = environment.api.medicalReportsTK

    getAccountsEmployee():Observable<AccountsEmployee>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/trad/ptes`;
        return this.http.get<GetResponseAccountsEmployee>(  url, { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }

    getModalErrorDetail(id: number | string):Observable<ModalDetalleError>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/trad/errordet/${id}`;
        return this.http.get<GetResponseModalDetalleError>(  url, { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }

    postCheckErrorPf(pf):Observable<CheckErrorPf>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/trad/error`;
        return this.http.post<GetResponseCheckErrorPf>(  url, pf , { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }

    getResultDln(id: number | string):Observable<ResultDln>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/data/${id}`;
        return this.http.get<GetResponseResultDln>(  url, { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }


    getDetDLN(cuerpo): Observable<ValidacionesModalDln> {

        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/det`;

        return this.http.post<GetResponseValidacionesModalDln>(  url, cuerpo, { headers } )
        .pipe(
            map( resp => resp.data )
        )

    }

    async getAcomodo(id: number) {
        return new Promise((resolve, reject) => {
            this.http
                .get<Default>(`${this.MEDICAL_TK_REPORTS}/tk/pf/acomodo/search/${id}`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async saveAcomodo(requestBody: any) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.MEDICAL_TK_REPORTS}/tk/pf/acomodo/save`, requestBody, { headers }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }

    postValidarParametro(parametro): Observable<ValidarParametroDln>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/do/val`;
        return this.http.post<GetResponseValidarParametroDln>(  url, parametro , { headers } ).pipe(
            map( resp => resp.data )
        )
    }


}
